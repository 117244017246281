
.ui.right.aligned.search.item {
  align-self: center;
}

.header > a {
  color: black;
}

.header > a:visited {
  color: grey;
}

.main-content-pushable {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content-pusher {
  flex: 1 0 auto;
}

footer {
  border-width: 0 0 0 0 !important;
  border-image-width: 0 0 0 0 !important;
  bottom: 0;
  margin: 0;
  flex-shrink: 0;
}

footer a {
  color: white;
}

.ui.container.framed {
  border-left-style: solid;
  border-right-style: solid;
  border-left-width: 2px;
  border-right-width: 2px;
  border-color: lightgray;
  background-color: white;
}

.centred {
  margin-left: auto !important;
  margin-right: auto !important;
}

.screen-centred {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 95vh;
  text-align: center;
  align-items: center;
}

@media only screen {
  .digit.draw {
    height: 100%;
    width: 100%;
  }
  .centred.drawing {
    height: 80vw;
    width: 100%;
    padding: 0 0 0 0;
  }
  .drawing-column {
    width: 96vw;
    margin-left: 2vw;
    margin-right: 2vw;
  }
}

@media only screen and (min-width: 320px) and (max-height: 319px) {
  .digit.draw {
    height: 100%;
    width: 100%;
  }
  .centred.drawing {
    height: 80vh;
    width: 80vh;
    padding: 0 0 0 0;
  }
  .drawing-column {
    width: 80vh;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 320px) and (min-height: 320px) {
  .digit.draw {
    height: 300px;
    width: 300px;
  }
  .centred.drawing {
    height: 300px;
    width: 300px;
    padding: 0 0 0 0;
  }
  .drawing-column {
    width: 307px;
    margin-left: auto;
    margin-right: auto;
  }
}